//@ts-check
import React from "react"
import { Typography } from "antd"
import { getTheme } from "../theme"
import { Dot } from "./Dot"
import { Flex } from "./Flex"

const { Title: AntTitle } = Typography

export const Title = ({
  children,
  style = {},
  level = 1,
  hasDot = false,
  leftDot = false,
}) =>
  !!children && (
    <AntTitle
      style={{
        fontFamily: "Signika",
        color: getTheme().titleColor,
        textAlign: "left",
        fontWeight: 600,
        ...style,
      }}
      // @ts-ignore
      level={level}
    >
      {children}
      {!!hasDot && (
        <Flex style={!leftDot && { justifyContent: "center" }}>
          <Dot marginTop={"10px"} display="default" />
        </Flex>
      )}
    </AntTitle>
  )
