//@ts-check
import React from "react"

export const Dot = ({
  size = 16,
  color = "#3BAEC9",
  display = "inline-block",
  marginTop = "15px",
}) => {
  return (
    <div
      style={{
        marginTop,
        height: size,
        width: size,
        backgroundColor: color,
        borderRadius: "50%",
        display,
      }}
    ></div>
  )
}
