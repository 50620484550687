//@ts-check
import React from "react"
import { Flex } from "./Flex"
import { Title } from "./Title"

export const CustomBloc = ({
  children,
  title = "",
  style = {},
  hasDot = true,
  contentStyle = {},
  ...otherProps
}) => {
  return (
    <Flex
      {...otherProps}
      style={{
        padding: "15px 30px",
        flexDirection: "column",
        ...style,
      }}
    >
      <Title style={{ textTransform: "capitalize" }} hasDot={hasDot}>
        {title}
      </Title>

      <div style={{ display: "flex", width: "100%", ...contentStyle }}>
        {children}
      </div>
    </Flex>
  )
}
